<template>
    <div>
        <div class="zozo-main-wrapper">


            <div id="main" class="main-section">

                <div data-zozo-parallax="2" data-zozo-parallax-position="left top"
                    class="page-title-section page-titletype-default page-titleskin-default page-titlealign-default zozo-parallax"
                    v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                    <div class="page-title-wrapper clearfix">
                        <div class="container">
                            <div class="page-title-container">
                                <div class="page-title-captions">
                                    <h1 class="entry-title " style="font-family: 'Cairo' !important;">
                                        {{ $t("about") }}</h1>
                                </div>
                                <div class="page-title-breadcrumbs">
                                    <div id="breadcrumb" class=""
                                        v-bind:class="[{'text_en' : $i18n.locale=='ar' },{'text_Ar':$i18n.locale=='en'}]">
                                        <router-link to="/" style="font-family: 'Cairo' !important;">
                                            {{ $t("home") }}</router-link>
                                        <i class="fas fa-angle-left" style="margin-left:5px;margin-right:5px"></i>
                                        <span class="current"
                                            style="font-family: 'Cairo' !important;">{{ $t("about") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zozo_parallax-inner" data-center="top: 0%;" data-bottom-top="top: 0%;"
                        data-top-bottom="top: -100%;" style="height: 200%; bottom: 0px;"></div>


                </div>



                <v-layout row wrap v-if="loading" style="min-height: 1000px">

                    <v-flex xs4></v-flex>
                    <v-flex xs4 style="text-align: center;margin-top:25px;position: relative;top: 100px;">

                        <v-progress-circular v-if="loading" indeterminate color="#276050" :size="120">
                        </v-progress-circular>

                    </v-flex>



                    <v-flex xs4></v-flex>





                </v-layout>


                <!-- <div data-zozo-parallax="2"
                    data-zozo-parallax-image="http://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/about-header-bg.jpg"
                    data-zozo-parallax-position="left top"
                    class="page-title-section page-titletype-default page-titleskin-default page-titlealign-center page-title-image-bg zozo-parallax">
                    <div class="page-title-wrapper clearfix">
                        <div class="container">
                            <div class="page-title-container">
                                <div class="page-title-captions">
                                    <h1 class="entry-title" style="font-family: 'Cairo' !important;">{{ $t("about") }}
                                    </h1>
                                    <div class="page-title-breadcrumbs">
                                        <div id="breadcrumb" class="breadcrumb zozo-breadcrumbs"><a
                                                style="font-family: 'Cairo' !important;"
                                                href="http://themes.zozothemes.com/charity/">{{ $t("home") }}</a> <span
                                                style="font-family: 'Cairo' !important;"
                                                class="current">{{ $t("about") }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zozo_parallax-inner" data-center="top: 0%;" data-bottom-top="top: 0%;"
                        data-top-bottom="top: -100%;"
                        style="height: 200%; background-image: url(&quot;http://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/about-header-bg.jpg&quot;); bottom: 0px;">
                    </div>
                </div> -->
                <div v-if="!loading" id="fullwidth" class="main-fullwidth main-col-full">
                    <section class="vc_row wpb_row vc_row-fluid vc_custom_1490936352178 vc-zozo-section typo-default">
                        <div class="zozo-vc-main-row-inner vc-normal-section">
                            <div class="container">
                                <div class="row"
                                    v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">

                                    <div class="wpb_column vc_main_column vc_column_container vc_col-sm-6 typo-default">
                                        <div class="vc_column-inner ">
                                            <div class="wpb_wrapper">
                                                <div class="zozo-parallax-header margin-bottom-30">
                                                    <div class="parallax-header content-style-default">
                                                        <h2 class="parallax-title text-left"
                                                            v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                                            {{ $t("about") }}</h2>
                                                        <div class="parallax-desc default-style text-left"
                                                            v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                                            <p
                                                                v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]">
                                                                {{about_first[0].description}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="vc_btn3-container vc_btn3-left"><a
                                                        class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-square vc_btn3-style-default vc_btn3-color-primary-bg"
                                                        href="http://themes.zozothemes.com/charity/what-we-do/"
                                                        data-title="" title="">Learn More</a></div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!loading"
                                        class="wpb_column vc_main_column vc_column_container vc_col-sm-6 typo-default">
                                        <div class="vc_column-inner ">
                                            <div class="wpb_wrapper">
                                                <div
                                                    class="wpb_video_widget wpb_content_element vc_clearfix   vc_video-aspect-ratio-169 vc_video-el-width-100 vc_video-align-left">
                                                    <div class="wpb_wrapper">
                                                        <div class="wpb_video_wrapper"
                                                            style="padding-top: 0px !important;">
                                                            <img src="https://abnakr.org/back_end/main.jpeg"
                                                                alt="" style="width:640px">
                                                            <!-- <iframe
                                                                title="When I Grow Up - Bigger Dreams for Kids Around the World"
                                                                src="https://player.vimeo.com/video/226219189?dnt=1&amp;app_id=122963"
                                                                width="640" height="360" frameborder="0"
                                                                allow="autoplay; fullscreen"
                                                                allowfullscreen=""></iframe> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section v-if="!loading"
                        class="vc_row wpb_row vc_row-fluid vc_custom_1490936387408 vc-zozo-section typo-default bg-style light-wrapper">
                        <div class="zozo-vc-main-row-inner vc-normal-section">
                            <div class="container">
                                <div class="row">
                                    <div
                                        class="wpb_column vc_main_column vc_column_container vc_col-sm-12 typo-default">
                                        <div class="vc_column-inner ">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                                    <div class="zozo-vc-row-inner vc-inner-row-section clearfix">
                                                        <div v-for="about  in about_around" :key="about"
                                                            class="wpb_column vc_column_inner vc_column_container vc_col-sm-4 typo-default">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="zozo-feature-box feature-box-style style-default-box style-sep-no  clearfix"
                                                                        id="feature-box-1">
                                                                        <div class="grid-item">
                                                                            <div
                                                                                class="grid-box-inner grid-text-center grid-box-exlarge grid-box-icon-circle grid-icon-shape grid-shape-none">
                                                                                <div class="grid-icon-wrapper icon-hv-br shape-icon-circle"
                                                                                    style="    background-color: #03adef;
    width: 100px;
    height: 100px;
    border: 3px;
    border-radius: 60px;
">
                                                                                    <i v-bind:class="about.img_file"
                                                                                        style="font-size: 48px;
    text-align: center;
    color: rgb(255, 255, 255);
    position: relative;
    top: 22px;" class="fa-2x"></i>
                                                                                </div>
                                                                                <h3 class="grid-title-below grid-title "
                                                                                    style="font-family: 'Cairo' !important;">
                                                                                    {{about.title}}</h3>
                                                                                <div class="grid-desc">
                                                                                    <p>{{about.description}}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div
                                                            class="wpb_column vc_column_inner vc_column_container vc_col-sm-4 typo-default">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="zozo-feature-box feature-box-style style-default-box style-sep-no  clearfix"
                                                                        id="feature-box-2">
                                                                        <div class="grid-item">
                                                                            <div
                                                                                class="grid-box-inner grid-text-center grid-box-exlarge grid-box-icon-circle grid-icon-shape grid-shape-none">
                                                                                <div
                                                                                    class="grid-icon-wrapper icon-hv-br shape-icon-circle">
                                                                                    <i
                                                                                        class="simple-icon icon-grid grid-icon zozo-icon icon-shape icon-circle icon-skin-default icon-bg pattern-1 icon-exlarge"></i>
                                                                                </div>
                                                                                <h3
                                                                                    class="grid-title-below grid-title ">
                                                                                    Vision</h3>
                                                                                <div class="grid-desc">
                                                                                    <p>Lorem ipsum dolor sit amet,
                                                                                        consectetur adipiscing elit.
                                                                                        Vestibulum nec odio ipsum.
                                                                                        Suspendisse cursus malesuada
                                                                                        facilisis.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="wpb_column vc_column_inner vc_column_container vc_col-sm-4 typo-default">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="zozo-feature-box feature-box-style style-default-box style-sep-no  clearfix"
                                                                        id="feature-box-3">
                                                                        <div class="grid-item">
                                                                            <div
                                                                                class="grid-box-inner grid-text-center grid-box-exlarge grid-box-icon-circle grid-icon-shape grid-shape-none">
                                                                                <div
                                                                                    class="grid-icon-wrapper icon-hv-br shape-icon-circle">
                                                                                    <i
                                                                                        class="fas fa-th simple-icon icon-charitys grid-icon zozo-icon icon-shape icon-circle icon-skin-default icon-bg pattern-1 icon-exlarge"></i>
                                                                                </div>
                                                                                <h3
                                                                                    class="grid-title-below grid-title ">
                                                                                    Mission</h3>
                                                                                <div class="grid-desc">
                                                                                    <p>Lorem ipsum dolor sit amet,
                                                                                        consectetur adipiscing elit.
                                                                                        Vestibulum nec odio ipsum.
                                                                                        Suspendisse cursus malesuada
                                                                                        facilisis.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section v-if="!loading" data-vc-parallax="1.5"
                        data-vc-parallax-image="http://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/testimonial-bg.jpg"
                        class="vc_row wpb_row vc_row-fluid vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving vc-zozo-section typo-light bg-overlay-dark">
                        <div class="zozo-vc-main-row-inner vc-normal-section">
                            <div class="container">
                                <div class="vc_tta-panel vc_active" id="1490613796141-29e222fa-0d0b"
                                    data-vc-content=".vc_tta-panel-body">
                                    <div class="vc_tta-panel-heading">
                                        <h4 class="vc_tta-panel-title"><a href="#1490613796141-29e222fa-0d0b"
                                                data-vc-accordion="" data-vc-container=".vc_tta-container"><span
                                                    class="vc_tta-title-text"></span></a></h4>
                                    </div>
                                    <div class="vc_tta-panel-body" style="">
                                        <div class="zozo-parallax-header margin-bottom-30">
                                            <div class="parallax-header content-style-default">
                                                <h2 class="parallax-title text-center"
                                                    style="font-family: 'Cairo' !important;">{{$t("org_goals") }}</h2>
                                            </div>
                                        </div>
                                        <div class="vc_row wpb_row vc_inner vc_row-fluid"
                                            v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                            <div class="row"
                                                v-bind:class="[{'grid-text-right' : $i18n.locale=='ar' },{'grid-text-left':$i18n.locale=='en'}]">
                                                <div class=" vc_col-sm-12 vc_col-lg-4 vc_col-md-4 "
                                                    v-for="about in about_goals" :key="about">
                                                    <div class="vc_column-inner "
                                                        v-bind:class="[{'grid-text-right' : $i18n.locale=='ar' },{'grid-text-left':$i18n.locale=='en'}]">
                                                        <div class="wpb_wrapper">
                                                            <div class="zozo-feature-box feature-box-style style-default-box style-sep-yes  clearfix"
                                                                id="feature-box-4">
                                                                <div class="grid-item"
                                                                    v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                                                    <div class="grid-box-inner  grid-box-normal grid-box-icon-none grid-shape-none"
                                                                        v-bind:class="[{'grid-text-right' : $i18n.locale=='ar' },{'grid-text-left':$i18n.locale=='en'}]">
                                                                        <div
                                                                            class="grid-icon-wrapper no-hover shape-icon-none">
                                                                            <i v-bind:class="about.img_file"
                                                                                class="simple-icon icon-bulb grid-icon zozo-icon icon-none icon-skin-default icon-bg pattern-1 icon-normal"></i>
                                                                        </div>
                                                                        <div class="grid-content-wrapper"
                                                                            style="    padding-right: 15px;">
                                                                            <h4 class="grid-title "
                                                                                v-bind:class="[{'menu-item_ar' : $i18n.locale=='ar' },{'menu-item_en':$i18n.locale=='en'}]">
                                                                                {{about.title}}</h4>
                                                                            <div class="grid-desc">
                                                                                <p>

                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_parallax-inner" data-bottom-top="top: -50%;" data-top-bottom="top: 0%;"
                            style="height: 150%; background-image: url(&quot;https://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/testimonial-bg.jpg&quot;);">
                        </div>
                    </section>
                    <section class="vc_row wpb_row vc_row-fluid vc_custom_1490936435737 vc-zozo-section typo-default">
                        <div class="zozo-vc-main-row-inner vc-normal-section">
                            <div class="container">
                                <div class="row">
                                    <div
                                        class="wpb_column vc_main_column vc_column_container vc_col-sm-12 typo-default">
                                        <div class="vc_column-inner ">
                                            <div class="wpb_wrapper">
                                                <div class="zozo-parallax-header margin-bottom-30">
                                                    <div class="parallax-header content-style-default">
                                                        <h2 class="parallax-title text-center"
                                                            style="font-family: 'Cairo' !important;">
                                                            {{ $t("Board_Directors") }}




                                                           </h2>
                                                    </div>
                                                </div>
                                                <div class="zozo-team-grid-wrapper team-box_type team-columns-4">
                                                    <div class="row team-grid-inner">
                                                        <div class="team-item col-md-3 col-sm-6 col-xs-12 text-center"
                                                            v-for="item in team" :key="item">
                                                            <div class="team-item-inner">
                                                                <div class="team-item-img" style="height: 277px;"><img
                                                                        :src="Url+'/team/'+item.img" style="    height: 100%;
    width: 100%;
" width="500" height="500" alt="Fruklein" class="img-responsive"></div>
                                                                <div class="team-content">
                                                                    <h5 class="team-member-name"><a
                                                                            title="Fruklein">{{item.name}}</a>
                                                                    </h5>


                                                                    <p><span
                                                                            class="team-member-designation">{{item.position}}</span>
                                                                    </p>
                                                                    <div class="team-member-desc">
                                                                        <p> {{item.Desc}}</p>
                                                                    </div>
                                                                    <!-- <div class="zozo-team-social">
                                                                        <ul
                                                                            class="zozo-social-icons soc-icon-transparent zozo-team-social-list">
                                                                            <li class="facebook"><a target="_blank"
                                                                                    href="https://zozothemes.com/"><i
                                                                                        class="fab fa-facebook"></i></a>
                                                                            </li>
                                                                            <li class="twitter"><a target="_blank"
                                                                                    href="https://zozothemes.com/"><i
                                                                                        class="fab fa-twitter"></i></a>
                                                                            </li>
                                                                            <li class="linkedin"><a target="_blank"
                                                                                    href="https://zozothemes.com/"><i
                                                                                        class="fab fa-linkedin"></i></a>
                                                                            </li>
                                                                            <li class="pinterest"><a target="_blank"
                                                                                    href="https://zozothemes.com/"><i
                                                                                        class="fab fa-pinterest"></i></a>
                                                                            </li>
                                                                        </ul>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>

        </div>
    </div>
</template>


<script>
    import Axios from "axios";
    import {
        EventBus
    } from '../../components/core/event-bus.js';


    export default {
        data: () => ({
            about_first: [],
            about_around: [],
            loading: true,


            about_goals: [],
            team: [{
                name_ar: " الدكتور فائز اموري الاسدي",
                name_en: " Dr. Fayez Emory Al-Asadi",
                img: "Fayez.jpg",
                position_ar: " رئيس المؤسسة رئيس مجلس الادارة",
                position_en: "Chairman of the Foundation Chairman of the Board of Directors",
                Desc_ar: "طبيب ممارس عام العمر ٥٣ عام يسكن في كربلاء لدية ٦ اطفال يعمل في مستشفى الحسيني العام في قسم التدريب والتطوير الصحي يترأس الجمعية الخيرية منذ تاسيسها في عام ٢٠٠٣ لكن سجلت رسميا في عام ٢٠٠٦ بدأ العمل بها بامكانيات بسيطة نجمع اموال المؤسسة من التبرع من رواتبنا الشهرية لدينا في المؤسسة حوالي ٢٠٠ طبيب بمختلف الاختصاصات ويكون عملنا تطوعي ولاننتمي لاي جهة سياسية او دينية اهدافنا خدمة الانسان وخصوصا الفقراء والايتام والمعوزين والارامل والمعوقين",
                Desc_en: "A general practitioner, 53 years old, lives in Karbala, has 6 children, works in Al-Husseini General Hospital in the Health Training and Development Department. He has headed the Charitable Society since its founding in 2003, but it was officially registered in 2006. It started working with simple means. We collect the institution’s money from the donation of our monthly salaries. The institution has about 200 doctors in various specialties, and our work is voluntary and does not belong to any political or religious party. Our goals are to serve the human being, especially the poor, orphans, the destitute, widows and the disabled."
            }]
        }),
        methods: {

            getByCatIdFirst(id) {
                this.loading = true;
                Axios.get("/aboutAs/getByCatId/" + id, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        //alert('sd');
                        this.loading = false;
                        this.about_first = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            getByCatIdSecond(id) {
                this.loading = true;
                Axios.get("/aboutAs/getByCatId/" + id, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.about_around = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            getByCatIdThread(id) {
                this.loading = true;
                Axios.get("/aboutAs/getByCatId/" + id, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.about_goals = res.data.data;

                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },

            //getByCatIdSecond
            changeLang() {

                if (this.$i18n.locale == 'ar') {
                    this.team = [{
                            name: " الدكتور فائز اموري الاسدي",
                            img: "Fayez3.jpg",
                            position: " رئيس المؤسسة رئيس مجلس الادارة",
                            Desc: "طبيب ممارس عام العمر ٥٣ عام يسكن في كربلاء لدية ٦ اطفال يعمل في مستشفى الحسيني العام في قسم التدريب والتطوير الصحي يترأس الجمعية الخيرية منذ تاسيسها في عام ٢٠٠٣ لكن سجلت رسميا في عام ٢٠٠٦ ",
                        },

                        {
                            name: " الاستاذ ابراهيم اسماعيل ابراهيم",
                            img: "lbrahim.jpg",
                            position: " المدير المفوض والمسئول المالي ",
                            Desc: " المدير المفوض والمسئول المالي وهو عصب ولولب الموسسة لانه متفرغ تماما لاعمال المؤسسة والكشف على الحالات المستحقة ومتابعة الفقراء وبناء البيوت  ومتابعة العمليات قبل دخولهم وبعد الخروج وتوفير العلاجات للمرضى والاوكسجين لمن يحتاج ",
                        },
                        {
                            name: "الدكتور احمد جعفر القرة غولي",
                            img: "ali.jpg",
                            position: "عضو مجلس الاداره",
                            Desc: "مواليد ١٩٦٩ لديه ٣ اولاد يعيش في كربلاء طبيب اختصاص نفسية وعصبية البورد العربي يعمل في مستشفى الحسيني التعليمي يرأس الكثير من اللجان الخاصة الطبية لديه الكثير من البحوث في امراض علم النفس والكأبة والذهان يرأس اللجنة الاعلامية واللجنة الخاصة بالام والطفل في المؤسسة",
                        },
                        {
                            name: " الدكتور مهند عبد الامير",
                            img: "muhend2.jpg",
                            position: " مسوؤل اللجنة الاجتماعية والخدمية",
                            Desc: "  دكتوراه في الطب الباطني البورد العربي لديه ٣ اولاد يسكن كربلاء يعمل في مستشفى الحسيني عمل عدة اشهر في ردهة فايروس كورونا منصبه في المؤسسة مسوؤل اللجنة الاجتماعية والخدمية وحلقة الوصل بيننا وبين ابطال الجيش العراقي ومتابعة مرضى وجرحى الجيش ويترأس لجنة الاشراف على العيادات المجانية  لخدمة المرضى وعوائل الايتام والمساكين والفقراء",
                        },
                         {
                            name: "عبد المحسن عبد العزيز الياسري",
                            img: "abd.png",
                            position: " الاب الروحي للمؤسسة",
                            Desc: "  رجل اعمال  لديه الفضل في التبرع لموقع الموسسة للسنوات الاولى بدون اجر مع تبرعات عينية وشهرية لم تنقطع ابدا يقوم بدعم الموسسة ماليا لكل ماتحتاجه وهو تلاب الروحي للمؤسسة",
                        }



                     

                    ]
                } else {
                    this.team = [{
                            name: " Dr. Fayez Emory Al-Asadi",
                            img: "Fayez3.jpg",
                            position: "Chairman of the Foundation Chairman of the Board of Directors",
                            Desc: "A general practitioner, age 53, lives in Karbala, has 6 children, works at Al-Husseini General Hospital in the Health Training and Development Department, and has headed the Charitable Society since its founding in 2003, but it was officially registered in",
                        },
                        {
                            name: " Professor Ibrahim Ismail Ibrahim",
                            img: "lbrahim.jpg",
                            position: " Managing Director and Financial Officer ",
                            Desc: "The Foundation is the authorized director and the financial official, who is the nerve and coil of the institution because he is fully devoted to the work of the Foundation, examining the outstanding cases, following up on the poor, building houses, following up on operations before entering and after leaving, providing treatments for patients and oxygen for those in need. ",
                        },
                        {
                            name: "Dr. Ahmed Jaafar Al-Qaraghuli",
                            img: "ali.jpg",
                            position: "Member of the Board of Directors",
                            Desc: "Born in 1969, has 3 children, lives in Karbala, a doctor specializing in psychiatry and neurology, the Arab Board, works in Al-Husseini Teaching Hospital, heads many special medical committees, has a lot of research in diseases of psychology, depression and psychosis,",
                        },
                        {
                            name: "Dr. Muhannad Abdul Amir",
                            img: "muhend2.jpg",
                            position: " Responsible for the Social and Service Committee",
                            Desc: "College of Medicine, Doctor of Internal Medicine, Arab Board, He has 3 children, He lives in Karbala, He works in Al-Husseini Hospital, He worked for several months in the Corona virus hall, His position in the institution is responsible for the Social and Service Committee and the link between us and the heroes of the Iraqi army, following up on the patients and wounded of the army, and chairs the Supervisory Committee On free clinics to serve patients, families of orphans, the needy and the poor",
                        },
                        
                         {
                            name: "Abdul Mohsen Abdul Aziz Al-Yasiri",
                            img: "abd.png",
                            position: "Godfather of the Foundation",
                            Desc: "  A businessman who has the credit for donating to the foundation’s website for the first years without pay, with in-kind and monthly donations that have never been interrupted.    "   ,
                        }



                    ]

                }




            },
        },

        created() {
            EventBus.$on("changeLangs", (from) => {
                from
                this.getByCatIdFirst(1)
                this.getByCatIdThread(2)
                this.getByCatIdSecond(3)
                this.changeLang();

            });
            this.getByCatIdFirst(1)
            this.getByCatIdThread(2)
            this.getByCatIdSecond(3)
             this.changeLang();
        },

    }
</script>